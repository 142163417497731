'use strict';

const isMobile = require('../util/isMobile');

const RavotclubFloatingButton = {};

let autoHideSpeechBalloon;

RavotclubFloatingButton.init = () => {
    if (!$('.js-ravotclub-floating-button').length) return;

    if (!sessionStorage.getItem('hideRavotclubFloatingButton')) {
        RavotclubFloatingButton.showButton();
        setTimeout(() => {
            RavotclubFloatingButton.showSpeechBalloon();
        }, 2000);
        RavotclubFloatingButton.typeTextInSpeechBalloon();
        autoHideSpeechBalloon = setTimeout(() => {
            RavotclubFloatingButton.hideSpeechBalloon();
        }, 10000);
    }

    RavotclubFloatingButton.onButtonClick();
    RavotclubFloatingButton.onCloseClick();
    RavotclubFloatingButton.onHover();

    if (window.location.hash === '#shoepa') {
        RavotclubFloatingButton.openShoepaSnippet();
    }
};

RavotclubFloatingButton.onButtonClick = () => {
    $('.js-ravotclub-floating-button-open').click(() => {
        RavotclubFloatingButton.openShoepaSnippet();
    });
};

RavotclubFloatingButton.onCloseClick = () => {
    $('.js-ravotclub-floating-button-close').click(() => {
        RavotclubFloatingButton.hideButton();
        sessionStorage.setItem('hideRavotclubFloatingButton', 'true');
    });
};

RavotclubFloatingButton.onHover = () => {
    if (isMobile.mobileOrTablet()) return;

    $('.js-ravotclub-floating-button').hover(() => {
        clearTimeout(autoHideSpeechBalloon);
        RavotclubFloatingButton.showSpeechBalloon();
    }, () => {
        clearTimeout(autoHideSpeechBalloon);
        RavotclubFloatingButton.hideSpeechBalloon();
    });
};

RavotclubFloatingButton.showButton = () => {
    $('.js-ravotclub-floating-button').removeClass('d-none');
};

RavotclubFloatingButton.hideButton = () => {
    $('.js-ravotclub-floating-button').addClass('d-none');
};

RavotclubFloatingButton.typeTextInSpeechBalloon = () => {
    const $speechBalloonText = $('.ravotclub-floating-button-speech-balloon__text');
    const text = $speechBalloonText.data('text');
    const textArray = text.split('');
    let charIndex = 0;
    const type = () => {
        if (charIndex < textArray.length) {
            $speechBalloonText.text($speechBalloonText.text() + textArray[charIndex]);
            charIndex++;
            setTimeout(type, 25);
        }
    };
    setTimeout(type, 2400);
};

RavotclubFloatingButton.showSpeechBalloon = () => {
    $('.ravotclub-floating-button-speech-balloon').addClass('show');
    $('.ravotclub-floating-button__bee').addClass('show');
};

RavotclubFloatingButton.hideSpeechBalloon = () => {
    $('.ravotclub-floating-button-speech-balloon').removeClass('show');
    $('.ravotclub-floating-button__bee').removeClass('show');
};

RavotclubFloatingButton.openShoepaSnippet = () => {
    if (window.openShoepaSnippet) {
        window.openShoepaSnippet();
    } else {
        const shoepaInterval = setInterval(() => {
            if (window.openShoepaSnippet) {
                window.openShoepaSnippet();
                clearInterval(shoepaInterval);
            }
        }, 250);
    }
};

module.exports = RavotclubFloatingButton;
