'use strict';

const MiniCartHelper = {};

MiniCartHelper.onCountUpdate = () => {
    $('.minicart').on('count:update', function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            $('.minicart .minicart-quantity .counter').text(count.quantityTotal);
        }
    });
};

MiniCartHelper.onChangeQuantity = () => {
    $('body').on('change', '.minicart .quantity', function () {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            location.reload();
        }
    });
};

MiniCartHelper.onUpdateAddToCart = () => {
    $('body').on('product:updateAddToCart', function () {
        localStorage.setItem('lastAddToBasket', new Date());
    });
};

module.exports = MiniCartHelper;
