'use strict';

const isMobile = require('../util/isMobile');
const MiniCartHelper = require('./miniCartHelper');
const { default: PerfectScrollbar } = require('perfect-scrollbar');

let updateMiniCart = true;

const showPopover = ($popover) => {
    $popover.show();
    setTimeout(function () {
        $popover.addClass('show');
    }, 200);
};

const closePopover = () => {
    $('.minicart .popover').removeClass('show');
    setTimeout(function () {
        $('.minicart .popover').hide();
    }, 200);
};

/**
 * Updates and refactors the callout messages
 */
const updateCalloutMessages = () => {
    let messages = [];
    let classes = [];

    $('.minicart-callouts div').each(function () {
        let classList = $(this).attr('class').split(/\s+/);
        if (!messages.includes($(this).text().trim())) {
            messages.push($(this).text().trim());
            if (classList.filter(x => x !== 'd-none').length) {
                classes.push(classList);
            }
        }
    });
    $('.minicart-callouts div').remove();
    messages.forEach(function (message, index) {
        $('.minicart-callouts').append(`<div class="${classes[index] ? classes[index].join(' ') : ''}">${message}</div>`);
    });
};

const miniCartSetup = () => {
    $('.minicart').on('mouseenter focusin touchstart', function (e, showCartOpenMessage) {
        if (!isMobile.any()) {
            if ($('.search:visible').length === 0) {
                return;
            }
            const url = $('.minicart').data('action-url'); // Cart-MiniCartShow
            const $popover = $(this).find('.popover');

            if (!updateMiniCart) {
                showPopover($popover);
                return;
            }

            if (!$popover.hasClass('show')) {
                showPopover($popover);
                $popover.spinner().start(true);
                $.get(url, function (data) {
                    $popover.empty();
                    $popover.append(data);
                    if ($popover.find('.product-summary').length) {
                        // eslint-disable-next-line no-new
                        new PerfectScrollbar($popover.find('.product-summary')[0], { suppressScrollX: true });
                    }
                    updateCalloutMessages();
                    if (showCartOpenMessage) $('.cartOpenMessage').removeClass('d-none');
                    updateMiniCart = false;
                    $.spinner().stop();
                });
            }
        }
    });
    $('.minicart').on('mouseleave focusout', function (event) {
        if ((event.type === 'focusout' && $('.minicart').has(event.target).length > 0)
            || (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity'))
            || $('body').hasClass('modal-open')) {
            event.stopPropagation();
            return;
        }
        closePopover();
    });
    $('body').on('product:afterAddToCart cart:afterRemoveProduct', function () {
        updateMiniCart = true;
    });
};

module.exports = {
    init: function () {
        MiniCartHelper.onCountUpdate();
        MiniCartHelper.onChangeQuantity();
        MiniCartHelper.onUpdateAddToCart();

        miniCartSetup();
    }
};
