'use strict';

const processInclude = require('base/util');

$(() => {
    require('./components/miniCart').init();
    require('./components/video').init();
    require('./components/search').init();
    require('./components/select').init();
    processInclude(require('./components/clientSideValidation'));
    require('./components/countrySelector').init();
    processInclude(require('./components/form'));
    require('./components/brandSlider').initSlider();
    require('./components/ravotclubFloatingButton').init();
});
