'use strict';

const debounce = require('lodash/debounce');
const isMobile = require('../util/isMobile');
const { default: PerfectScrollbar } = require('perfect-scrollbar');

const endpoint = $('.suggestions-wrapper').data('url');
const minChars = 3;
var psSearch = null;
/**
 * Retrieves Suggestions element relative to scope
 *
 * @param {Object} scope - Search input field DOM element
 * @return {JQuery} - .suggestions-wrapper element
 */
function getSuggestionsWrapper(scope) {
    return $(scope).siblings('.suggestions-wrapper');
}

/**
 * Determines whether DOM element is inside the .search-mobile class
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 * @return {boolean} - Whether DOM element is inside  div.search-mobile
 */
function isMobileSearch(scope) {
    return !!$(scope).closest('.search-mobile').length;
}

/**
 * Remove modal classes needed for mobile suggestions
 *
 */
function clearModals() {
    $('body').removeClass('modal-open');
    $('body').removeClass('fixed');
    $('.suggestions').removeClass('modal');
}

/**
 * Apply modal classes needed for mobile suggestions
 *
 * @param {Object} scope - Search input field DOM element
 */
function applyModals(scope) {
    if (isMobileSearch(scope)) {
        $('body').addClass('modal-open');
        getSuggestionsWrapper(scope).find('.suggestions').addClass('modal');
    }
}

/**
 * Determines whether the "More Content Below" icon should be displayed
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 */
function handleMoreContentBelowIcon(scope) {
    if (($(scope).scrollTop() + $(scope).innerHeight()) >= $(scope)[0].scrollHeight) {
        $('.more-below').fadeOut();
    } else {
        $('.more-below').fadeIn();
    }
}

/**
 * Positions Suggestions panel on page
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 */
function positionSuggestions(scope) {
    var outerHeight;
    var $scope;
    var $suggestions;
    var top;

    if (isMobileSearch(scope)) {
        $scope = $(scope);
        top = $scope.offset().top;
        outerHeight = $scope.outerHeight();
        $suggestions = getSuggestionsWrapper(scope).find('.suggestions');
        $suggestions.css('top', top + outerHeight);

        handleMoreContentBelowIcon(scope);

        // Unfortunately, we have to bind this dynamically, as the live scroll event was not
        // properly detecting dynamic suggestions element's scroll event
        $suggestions.scroll(function () {
            handleMoreContentBelowIcon(this);
        });
    }
}

/**
 * Process Ajax response for SearchServices-GetSuggestions
 *
 * @param {Object|string} response - Empty object literal if null response or string with rendered
 *                                   suggestions template contents
 */
function processResponse(response) {
    var $suggestionsWrapper = getSuggestionsWrapper(this).empty();

    $('.suggestions').spinner().stop();
    if (!(typeof (response) === 'object')) {
        $suggestionsWrapper.append(response).show();
        // $('.suggestions').perfectScrollbar({ suppressScrollX: true });
        psSearch = new PerfectScrollbar('.suggestions', { suppressScrollX: true });
        positionSuggestions(this);

        if (isMobile.any()) {
            const height = $('.header.header-wrapper').height() + $('.header.header-sticky-wrapper').height();
            $('div.suggestions').css('top', height);
        }

        if (isMobileSearch(this)) {
            applyModals(this);
        }
        if (isMobile.any()) {
            $('body').addClass('fixed');
        }
    } else {
        $suggestionsWrapper.hide();
    }
}

/**
 * Retrieve suggestions
 *
 * @param {Object} scope - Search field DOM element
 */
function getSuggestions(scope) {
    if ($(scope).val().length >= minChars) {
        $('.suggestions').spinner().start(true);
        const queryString = encodeURIComponent($(scope).val());
        $.ajax({
            context: scope,
            url: endpoint + queryString,
            method: 'GET',
            success: processResponse,
            error: function () { $('.suggestions').spinner().stop(); }
        });
    } else {
        clearModals();
        getSuggestionsWrapper(scope).empty();
    }
}

module.exports = {
    init: function () {
        $('input.search-field').each(function () {
            /**
             * Use debounce to avoid making an Ajax call on every single key press by waiting a few
             * hundred milliseconds before making the request. Without debounce, the user sees the
             * browser blink with every key press.
             */
            var debounceSuggestions = debounce(getSuggestions, 300);

            $(this).on('keyup click', function (e) {
                debounceSuggestions(this, e);
            });
        });

        $('body').on('click', function (e) {
            if (!$('.suggestions').has(e.target).length && !$(e.target).hasClass('search-field')) {
                $('.suggestions').hide();
                $('body').removeClass('fixed');
                if (psSearch != null) {
                    psSearch.destroy();
                    psSearch = null;
                }
            }
        });
    }
};
