'use strict';

const CountrySelector = {};

CountrySelector.init = () => {
    CountrySelector.onClick();
    CountrySelector.onFocusIn();
};

CountrySelector.loadLocale = (localeCode, localeCurrencyCode) => {
    let action = $('.page').data('action');
    const queryString = $('.page').data('querystring');
    const url = $('.country-selector').data('url');

    if (action === 'Home-Content') {
        action = 'Home-Show';
    }

    $.ajax({
        url: url,
        type: 'get',
        dataType: 'json',
        data: {
            code: localeCode,
            queryString: queryString,
            CurrencyCode: localeCurrencyCode,
            action: action
        },
        success: (response) => {
            $.spinner().stop();
            if (response && response.redirectUrl) {
                window.location.href = response.redirectUrl;
            }
        },
        error: () => {
            $.spinner().stop();
        }
    });
};

CountrySelector.onClick = () => {
    $('body').on('click', '.country-selector a', (event) => {
        event.preventDefault();
        const $this = $(event.currentTarget);
        const localeCode = $this.data('locale');
        const localeCurrencyCode = $this.data('currencycode');

        CountrySelector.loadLocale(localeCode, localeCurrencyCode);
    });
};

CountrySelector.onFocusIn = () => {
    $('.navbar-header .country-selector').on('focusin', function () {
        $(this).addClass('show').children('.dropdown-menu').addClass('show');
    });
};

module.exports = CountrySelector;
