'use strict';

var isMobile = require('../util/isMobile');
var sliderUtil = require('../util/slider');

module.exports = {
    initSlider: function () {
        $('.brand-slider').each((i, brandSlider) => {
            sliderUtil.initializeSlider(brandSlider, isMobile.any() ? 2 : 5, true);
        });
    }
};
