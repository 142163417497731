'use strict';

const isMobile = require('../util/isMobile');

module.exports = {
    init: function () {
        if (isMobile.any()) {
            var mobileVideos = $('video source[data-mobile]');

            mobileVideos.each(function () {
                var $mobileVideo = $(this);
                var $videoParent = $(this).parent();
                $videoParent[0].pause();
                $mobileVideo.attr('src', $mobileVideo.data('mobile'));
                $videoParent[0].load();
            });
        }

        var $videos = $('.jsVideo-autoplay');

        const intersectionObserver = new IntersectionObserver(function (entries) {
            entries.forEach(function (entry) {
                var $target = $(entry.target);
                if (entry.intersectionRatio <= 0.25) {
                    entry.target.pause();
                    return;
                }

                if (!$target.data('ended')) {
                    entry.target.play();
                }
            });
        }, {
            threshold: [0.25, 0.50]
        });

        $videos.each(function (index, video) {
            intersectionObserver.observe(video);
        });

        $('.jsVideo-norestart').on('ended', function () {
            $(this).attr('data-ended', true);
        });
    }
};
